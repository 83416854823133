import styled from 'styled-components';
import media from 'styled-query';
import {
  Form as oForm,
  Input as oInput,
  Select as oSelect,
} from '@rocketseat/unform';

export const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelInputImage = styled.label`
  input {
    display: none;
  }
  img {
    border: 1px dotted #e71a6938;
  }
  img:hover {
    cursor: pointer;
  }
`;

export const LabelInputImageFrame = styled.label`
  input {
    display: none;
  }
  img {
    border: 1px dotted #e71a6938;
    width: 100%;
  }
  img:hover {
    cursor: pointer;
  }
`;

export const DivButton = styled.div`
  display: flex;
  justify-content: center;
  padding: 25px 0;
  width: 100%;
  button {
    width: 150px;
  }
`;

export const DivNew = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 15px;
`;

export const DivBack = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 15px;
`;

export const Form = styled(oForm)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  flex: 1;

  small {
    /* color: #3079ff; */
    color: #4a8aff;
    margin-left: 3px;
    margin-bottom: 5px;
  }

  img {
    width: 100%;
  }

  .not-visible {
    display: none;
  }

  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    /* background: red; */
    flex-direction: column;
  `}
`;

export const DivInput = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  margin: 0 1rem 1rem 1rem;
  /* width: calc(25% - 2rem); */

  width: calc(${props => props.propW} - 2rem);

  border-left: 1px solid ${props => props.propBorder ? '#eff6ff' : 'white'};

  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    /* background: red; */
    width: 100%;
    margin: 0;
  `}
`;

export const Input = styled(oInput)`
  background: #fff;
  border: 1px solid #00bfff;
  color: #545454;
  border-radius: 5px;
  font-size: 15px;

  &:disabled {
    background: #a7bad43d;
    color: #545454b5;
    cursor: not-allowed;
  }
`;

export const Select = styled(oSelect)`
  background: #fff;
  border: 1px solid #00bfff;
  color: #545454;
  border-radius: 5px;
  height: 35px;
  font-size: 15px;

  &:disabled {
    background: #a7bad43d;
    color: #545454b5;
    cursor: not-allowed;
  }
`;

export const DivContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonTypeMedia = styled.div`
  background: #fff;
  border: 1px solid #00bfff;
  color: #545454;
  border-radius: 5px;
  height: 35px;
  font-size: 15px;
  display: flex;

  .isSelected {
    background: #3079ff3b;
  }

  div {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    cursor: pointer;

  }
`;

export const SongImage = styled.img`
  width: 100%;
  height: auto;
`;

export const Dropzone = styled.div`
  border-radius: 4px;
  padding: 30px;
  text-align: center;
  cursor: ${props => props.propEnabled ? 'pointer' : 'progress'};
  color: ${props => props.propEnabled ? '#e71a69' : '#b7b7b7'};
  border: ${props => props.propEnabled ? '1px' : '2px'} dashed ${props => props.propEnabled ? '#e4c1cb' : '#b7b7b7'};
  & > p {
    margin: 0;
    font-size: ${props => props.propEnabled ? '15px' : '18px'};
  };
  &:hover {
    border: 2px dashed ${props => props.propEnabled ? '#e71a69' : '#b7b7b7'};
  };
`;

export const Title = styled.span`
  color: #4a8aff;
  margin-left: 3px;
  margin-bottom: 5px;
`;

export const Modal = styled.div`
  background: rgba(242,243,245, 0.8);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
`;

export const ModalDivUpload = styled.div`
  background: white;
  width: 100%;
  max-width: 400px;
  padding: 2rem 3rem;
  border-radius: 5px;
  box-shadow: 0 0 60px rgba(0,0,0,0.05);
  text-align: center;
  position: relative;
`;

export const Hr = styled.hr`
  border-color: #00c5ca;
`

export const ModalHeader = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #4a8aff;
`;

export const ModalFooter = styled.span`
  font-size: 16px;
  color: #4a8aff;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    margin-left: 10px;
  }
`;

export const ModalDivArtist = styled.div`
  background: white;
  width: 100%;
  max-width: 800px;
  min-height: 600px;
  max-height: 600px;
  padding: 2rem 3rem;
  border-radius: 5px;
  box-shadow: 0 0 60px rgba(0,0,0,0.05);
  text-align: center;
  position: relative;
`;


export const ProgressImg = styled.img`
  width: 75px;
  padding: ${props => props.propPadding ? '15px' : '0px'};
`;
