import styled from 'styled-components';
import { Link as oLink } from 'react-router-dom';
import Menu from '@material-ui/icons/Menu';

export const Link = styled(oLink)`
  text-decoration: none;
  color: #00bfff;
  &:hover {
    color: #00ffaa;
  }
`;

export const Img = styled.img`
  height: 100%;
  width: auto;
`;

export const MenuIcon = styled(Menu)`
  color: #00bfff;
  /* color: #    color: #00ffaa;
; */
`;
