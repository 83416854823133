import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

import Main from './pages/Main';

import Player from './pages/Player';

import Test from './pages/Test';
import Login from './pages/Login';
import Logout from './pages/Logout';
import About from './pages/About';
import NotFound from './pages/NotFound';

import User from './pages/User';
import UserManager from './pages/UserManager';

import Influencer from './pages/Influencer';
import InfluencerManager from './pages/InfluencerManager';

import Plan from './pages/Plan';
import PlanManager from './pages/PlanManager';

import Live from './pages/Live';
import LiveManager from './pages/LiveManager';

import Catalog from './pages/Catalog';
import CatalogManager from './pages/CatalogManager';

import Media from './pages/Media';
import MediaManager from './pages/MediaManager';

// import Category from './pages/Category';
// import CategoryManager from './pages/CategoryManager';

// import Label from './pages/Label';
// import LabelManager from './pages/LabelManager';

// import Artist from './pages/Artist';
// import ArtistManager from './pages/ArtistManager';

// import Album from './pages/Album';
// import AlbumManager from './pages/AlbumManager';

// import Song from './pages/Song';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <AuthenticatedRoute exact path="/" component={Main} />

        <UnauthenticatedRoute path="/login" exact component={Login} />
        <Route path="/test" exact component={Test} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/about" exact component={About} />

        <AuthenticatedRoute exact path="/user" component={User} />
        <AuthenticatedRoute exact path="/user/:id" component={UserManager} />
        <AuthenticatedRoute exact path="/influencer" component={Influencer} />
        <AuthenticatedRoute exact path="/influencer/:id" component={InfluencerManager} />

        <AuthenticatedRoute exact path="/plan" component={Plan} />
        <AuthenticatedRoute exact path="/plan/:id" component={PlanManager} />

        <AuthenticatedRoute exact path="/live" component={Live} />
        <AuthenticatedRoute exact path="/live/:id" component={LiveManager} />

        <AuthenticatedRoute exact path="/catalog" component={Catalog} />
        <AuthenticatedRoute exact path="/catalog/:id" component={CatalogManager} />

        <AuthenticatedRoute exact path="/media" component={Media} />
        <AuthenticatedRoute exact path="/media/:id" component={MediaManager} />

        {/* <AuthenticatedRoute exact path="/category" component={Category} />
        <AuthenticatedRoute exact path="/category/:id" component={CategoryManager} />
        <AuthenticatedRoute exact path="/label" component={Label} />
        <AuthenticatedRoute exact path="/label/:id" component={LabelManager} />
        <AuthenticatedRoute exact path="/artist" component={Artist} />
        <AuthenticatedRoute exact path="/artist/:id" component={ArtistManager} />
        <AuthenticatedRoute exact path="/album" component={Album} />
        <AuthenticatedRoute exact path="/album/:id" component={AlbumManager} />
        <AuthenticatedRoute exact path="/song" component={Song} /> */}


        <AuthenticatedRoute exact path="/player/:id" component={Player} />
        {/* <AuthenticatedRoute exact path="/medias/new" component={MediasNew} /> */}
        {/* <AuthenticatedRoute exact path="/medias/:id" component={MediasView} />
        <AuthenticatedRoute exact path="/medias" component={Medias} />
        <AuthenticatedRoute exact path="/lives/:id" component={LivesView} />
        <AuthenticatedRoute exact path="/lives" component={Lives} />
        <AuthenticatedRoute exact path="/channels" component={Channels} />
        <AuthenticatedRoute exact path="/channels/:id" component={ChannelsView} />
        <AuthenticatedRoute exact path="/channels/:id/new" component={ChannelsNew} />
        <AuthenticatedRoute exact path="/channels/:id/media" component={MediasNew} /> */}



        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}
