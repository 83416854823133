import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';
import { NotificationContainer } from 'react-notifications';
import api from '../../services/api';
import LiveManagerContext from './LiveManagerContext';

import NavBar from '../../components/NavBar';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import Line from '../../components/Line';
import NavTabsGeneral from '../../components/NavTabsGeneral';

import TabInfo from './Tabs/Info';
import TabImage from './Tabs/Image';
import TabSource from './Tabs/Source';

import * as S from './styles';

const LiveManager = props => {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [isContent, setContent] = useState(false);
  const [isNewData, setNewData] = useState(false);
  const [objData, setData] = useState({});

  const auxFormatData = data => {
    data.id = data.livId;
    switch (data.livStatus) {
      case 0:
        data._livStatus = 'Active';
        break;
      case 2:
        data._livStatus = 'Disabled';
        break;
      default:
        data._livStatus = 'Disabled';
        break;
    }
    data._livDateCreated = format(new Date(data.livDateCreated), 'MM/dd/yyyy');
    data._livDateUpdated = format(new Date(data.livDateUpdated), 'MM/dd/yyyy');
    return data;
  };

  useEffect(() => {
    async function loadData() {
      const {
        match: {
          params: { id },
        },
      } = props;

      if (id === 'new') {
        // New Data
        setNewData(true);
        viewContent();
      } else {
        const apiGetData = api('GET', `/live/${id}`, null);

        const resGetData = await apiGetData;
        const {
          data: { resData },
        } = resGetData;
        console.log(resData);
        // console.log(auxFormatData(resData));
        setData(auxFormatData(resData));
        viewContent();
      }
    }
    try {
      loadData();
    } catch (error) {
      viewError();
    }
  }, []);

  const viewData = () => {
    return (
      <S.DivData>
        <NavTabsGeneral
          propContext={objData}
          propTabs={[
            {
              title: 'Info',
              component: <TabInfo propData={objData} propNewData={isNewData} />,
            },
            !isNewData && {
              title: 'Images',
              component: <TabImage propData={objData} />,
            },
            !isNewData && {
              title: 'Source locations',
              component: <TabSource propData={objData} />,
            },
          ]}
        />
      </S.DivData>
    );
  };

  const viewLoading = status => {
    if (status === undefined) {
      console.log('type A');
      setContent(false);
      setError(false);
      setLoading(true);
    } else {
      console.log('type B');
      setLoading(status);
    }
  };

  const viewError = () => {
    setContent(false);
    setLoading(false);
    setError(true);
  };

  const viewContent = () => {
    setLoading(false);
    setError(false);
    setContent(true);
  };

  return (
    <>
      <NavBar />
      <S.Container>
        <S.ContainerHeader>
          <S.Title>Live</S.Title>
          <Line />
        </S.ContainerHeader>
        <S.ContainerContent>
          <LiveManagerContext.Provider
            value={{ viewLoading, viewError, viewContent }}
          >
            {isLoading && <Loader />}
            {isError && <Error />}
            {isContent && viewData()}
            {/* {isLoading ? <Loader /> : viewData()} */}
          </LiveManagerContext.Provider>
        </S.ContainerContent>
      </S.Container>
      <NotificationContainer />
    </>
  );
};

export default LiveManager;
