// Engaged Tv ADM
export default {
  info: {
    NAME: 'stingr-app',
  },
  api: {
    BASE_URL: 'api.stingrtv.com',
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'pxt0662ut5', // app-system
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_nULhiwyzd",
    APP_CLIENT_ID: "633860rua9em4j3esank83cgi0",
    IDENTITY_POOL_ID: "us-east-1:f88436d2-b8d2-4f42-8ed1-9ad25b9f1e57"
  },
  pinpoint: {
    APP_ID: "adc5753fa2444f9eb67bc131e6a8fe41",
    REGION: "us-east-1",
  },
  // --------------------------------------------------------------------------------- S3 AUTH -----
  S3_THUMBNAILS_PROFILE: {
    REGION: "us-east-1",
    BUCKET: "stingr-thumbnails-profile",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_INFLUENCER: {
    REGION: "us-east-1",
    BUCKET: "stingr-thumbnails-influencer",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  // ------------------------------------------------------------------------------- S3 MOVIES -----
  S3_THUMBNAILS_LIVE: {
    REGION: "us-east-1",
    BUCKET: "stingr-thumbnails-live",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_CATALOG: {
    REGION: "us-east-1",
    BUCKET: "stingr-thumbnails-catalog",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_MEDIA: {
    REGION: "us-east-1",
    BUCKET: "stingr-thumbnails-media",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  // --------------------------------------------------------------------------------- S3 FILM -----
  S3_MEDIA_FILM: {
    REGION: "us-east-1",
    BUCKET: "stingr-vod-movies-source-11otmg1usow1b",
    MAX_ATTACHMENT_SIZE: 10000000000, //10 GB
  },
  // --------------------------------------------------------------------------------- S3 SONG -----
  S3_THUMBNAILS_CATEGORY: {
    REGION: "us-east-1",
    BUCKET: "stingr-thumbnails-category",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_LABEL: {
    REGION: "us-east-1",
    BUCKET: "stingr-thumbnails-label",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_ARTIST: {
    REGION: "us-east-1",
    BUCKET: "stingr-thumbnails-artist",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_ALBUM: {
    REGION: "us-east-1",
    BUCKET: "stingr-thumbnails-album",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_SONG: {
    REGION: "us-east-1",
    BUCKET: "stingr-thumbnails-song",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
};
