import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';
import api from '../../services/api';

import NavBar from '../../components/NavBar';
import Table from '../../components/Table';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import Line from '../../components/Line';
// import ButtonNew from '../../components/ButtonNew';

import * as S from './styles';
import { led } from '../../utils/led';

const Live = () => {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [lstData, setData] = useState([]);
  const objColumns = [
    {
      title: '',
      field: '_livStatus',
      formatter: 'html',
      width: 15,
      align: 'center',
    },
    { title: 'Title', field: 'livTitle', headerFilter: 'input' },
    {
      title: 'Language',
      field: 'livLanguage',
      formatter: 'html',
      width: 200,
      align: 'center',
    },
    {
      title: 'Source Locations',
      field: 'livSourceLocations',
      formatter: 'html',
      width: 250,
      align: 'center',
    },
    { title: 'Category', field: 'livCategory', headerFilter: 'input' },
    {
      title: 'Created',
      field: '_livDateCreated',
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
    {
      title: 'Updated',
      field: '_livDateUpdated',
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
  ];

  const auxFormatData = objData => {
    // objData.id = objData.livId;
    objData.id = objData.livLanguage;
    objData._livStatus = led(objData.livStatus);
    objData._livDateCreated = format(
      new Date(objData.livDateCreated),
      'MM/dd/yyyy'
    );
    objData._livDateUpdated = format(
      new Date(objData.livDateUpdated),
      'MM/dd/yyyy'
    );
    return objData;
  };

  useEffect(() => {
    async function loadData() {
      const resGetData = await api('GET', '/live', null);
      const {
        data: { resData },
      } = resGetData;

      console.log(resData);
      const tmpData = resData !== null ? resData.map(auxFormatData) : [];
      setData(tmpData);
      setLoading(false);
    }

    try {
      loadData();
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  }, []);

  // propDblClickRedirect="/artist"
  // propDblClickRedirect="undefined"
  const viewData = () => {
    return !isError ? (
      <Table
        propColumns={objColumns}
        propData={lstData}
        propDblClickRedirect="/live"
      />
    ) : (
      <Error />
    );
  };

  return (
    <>
      <NavBar />
      <S.Container>
        <S.ContainerHeader>
          <S.ContainerTitle>
            <S.Title>Lives</S.Title>
            {/* <ButtonNew propLink="/live/new" /> */}
          </S.ContainerTitle>
          <Line />
        </S.ContainerHeader>
        <S.ContainerContent>
          {isLoading ? <Loader /> : viewData()}
        </S.ContainerContent>
      </S.Container>
    </>
  );
};

export default Live;
