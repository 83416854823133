import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  NotificationContainer,
  NotificationManager as N,
} from 'react-notifications';

import { useHistory } from 'react-router-dom';

import api from '../../../../services/api';
import PlanManagerContext from '../../PlanManagerContext';

import ButtonLoader from '../../../../components/ButtonLoader';
import RatingView from '../../../../components/RatingView';

import 'react-notifications/lib/notifications.css';
import * as S from './styles';

const Info = ({ propData, propNewData }) => {
  const history = useHistory();
  const { viewLoading, viewError, viewContent } = useContext(
    PlanManagerContext
  );

  const [objData, setData] = useState(propData);
  // console.log(objData);

  const optStatus = [
    { id: 'Active', title: 'Active' },
    // { id: 'Processing', title: 'Processing' },
    { id: 'Disabled', title: 'Disabled' },
    // { id: 'Blocked', title: 'Blocked' },
  ];

  const optRecurrence = [
    { id: 'Y', title: 'Yearly' },
    { id: 'M', title: 'Monthly' },
    { id: 'F', title: 'Free' },
  ];

  useEffect(() => {
    async function loadData() {
      viewContent();
    }
    try {
      loadData();
    } catch (error) {
      viewError();
    }
  }, []);

  const handleSubmit = async data => {
    try {
      if (handleValidate()) {
        if (propNewData) {
          viewLoading();
          console.log(`Data POST`, data);
          const resPostData = await api('POST', `/plan`, data);
          console.log(resPostData);

          N.success('Successfully created');

          setTimeout(() => {
            history.push(`/plan/${resPostData.data.resData.plaId}`);
            window.location.reload();
          }, 1500);
        } else {
          viewLoading();
          console.log(`Data PUT`, data);
          const resPutData = await api(
            'PUT',
            `/plan/${objData.plaId}/info`,
            data
          );
          console.log(resPutData);

          N.success('Successfully updated');

          setTimeout(() => {
            // history.push('/');
            window.location.reload();
          }, 1500);
        }
      }

      function handleValidate() {
        let varControl = true;
        let varTemp = null;

        console.log(data);

        if (data._plaStatus !== 'Active' && data._plaStatus !== 'Disabled') {
          varControl = false;
          N.error('Status', 'Check parameters');
        } else {
          switch (data._plaStatus) {
            case 'Active':
              data.plaStatus = 0;
              break;
            case 'Disabled':
              data.plaStatus = 2;
              break;
            default:
              varControl = false;
              N.error('Error Status', 'Check parameters');
              break;
          }
        }

        if (data.plaOrder === '') {
          varControl = false;
          N.error('Order', 'Check parameters');
        }
        varTemp = parseFloat(data.plaOrder);
        if (varTemp <= 0 || varTemp > 10) {
          varControl = false;
          N.error('Order', 'Check parameters');
        }
        data.plaOrder = varTemp;

        if (data.plaName === '') {
          varControl = false;
          N.error('Name', 'Check parameters');
        }

        if (data.plaAbout === '') {
          varControl = false;
          N.error('About', 'Check parameters');
        }

        // if (data._plaRecurrence === '') {
        //   varControl = false;
        //   N.error('About', 'Check parameters');
        // }
        // data.plaRecurrence = data._plaRecurrence;

        if (data.plaRecurrence === 'F') {
          data.plaPrice = 0;
          data.plaPricePromotional = 0;
        } else {
          if (data.plaPrice === '') {
            varControl = false;
            N.error('Price', 'Check parameters');
          }
          varTemp = parseFloat(data.plaPrice);
          if (varTemp < 0 || varTemp > 1000) {
            varControl = false;
            N.error('Price', 'Check parameters');
          }
          data.plaPrice = varTemp;

          // if (data.plaPricePromotional === '') {
          //   varControl = false;
          //   N.error('Price Promotional', 'Check parameters');
          // }
          // varTemp = parseFloat(data.plaPricePromotional);
          // if (varTemp < 0 || varTemp > 1000) {
          //   varControl = false;
          //   N.error('Price Promotional', 'Check parameters');
          // }
          data.plaPricePromotional = varTemp;
        }

        if (data.plaKeyStripeDev === '') {
          varControl = false;
          N.error('Key Stripe DEV', 'Check parameters');
        }

        if (data.plaKeyStripe === '') {
          varControl = false;
          N.error('Key Stripe PROD', 'Check parameters');
        }

        if (data.plaKeyAndroid === '') {
          varControl = false;
          N.error('Key Android', 'Check parameters');
        }

        if (data.plaKeyIos === '') {
          varControl = false;
          N.error('Key Ios', 'Check parameters');
        }

        return varControl;
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 409) {
        N.error(error.response.data.resMessage);
        viewContent();
      } else {
        N.error('Error save');
        viewContent();
      }
    }
  };

  return (
    <>
      <S.Form onSubmit={handleSubmit} initialData={objData}>
        <S.DivInput propW="34%">
          <small>Status</small>
          <S.Select name="_plaStatus" options={optStatus} disabled={false} />
        </S.DivInput>
        <S.DivInput propW="33%">
          <small>Date Created</small>
          <S.Input name="_plaDateCreated" disabled />
        </S.DivInput>
        <S.DivInput propW="33%">
          <small>Date Updated</small>
          <S.Input name="_plaDateUpdated" disabled />
        </S.DivInput>
        <S.DivInput propW="20%">
          <small>Order</small>
          <S.Input name="plaOrder" type="number" min="1" max="10"disabled={false} />
        </S.DivInput>
        <S.DivInput propW="80%">
          <small>Name</small>
          <S.Input name="plaName" disabled={false} />
        </S.DivInput>
        <S.DivInput propW="100%">
          <small>About</small>
          <S.Input name="plaAbout" disabled={false} />
        </S.DivInput>
        <S.DivInput propW="20%">
          <small>Ads</small>
          <S.Input name="plaAds" disabled={true} />
        </S.DivInput>
        <S.DivInput propW="20%">
          <small>Type</small>
          <S.Input name="plaType" disabled={true} />
        </S.DivInput>
        <S.DivInput propW="20%">
          <small>Recurrence</small>
          <S.Select name="plaRecurrence" options={optRecurrence} disabled={false} />
        </S.DivInput>
        <S.DivInput propW="20%">
          <small>Price</small>
          <S.Input name="plaPrice" disabled={false} type="number" />
        </S.DivInput>
        <S.DivInput propW="20%">
          <small>Price Promotional</small>
          <S.Input name="plaPricePromotional" disabled={true} type="number" />
        </S.DivInput>
        <S.DivInput propW="20%">
          <small>Key Stripe DEV</small>
          <S.Input name="plaKeyStripeDev" disabled={false} />
        </S.DivInput>
        <S.DivInput propW="20%">
          <small>Key Stripe PROD</small>
          <S.Input name="plaKeyStripe" disabled={false} />
        </S.DivInput>
        <S.DivInput propW="20%">
          <small>Key Android</small>
          <S.Input name="plaKeyAndroid" disabled={false} />
        </S.DivInput>
        <S.DivInput propW="20%">
          <small>Key Ios</small>
          <S.Input name="plaKeyIos" disabled={false} />
        </S.DivInput>
        <S.DivButton>
          <ButtonLoader propTitle="SAVE" propSpinner={false} />
        </S.DivButton>
      </S.Form>
      <NotificationContainer />
    </>
  );
};

Info.defaultProps = {
  propData: {},
  propNewData: false,
};

Info.propTypes = {
  propData: PropTypes.object,
  propNewData: PropTypes.bool,
};

export default Info;
